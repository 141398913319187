import { AfterViewInit, Component, NgZone, OnInit, inject } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { IonApp } from '@ionic/angular/standalone';
import { Capacitor } from '@capacitor/core';
import { HapticHelper } from './shared/helpers/haptic.helper';
import { CommonModule } from '@angular/common';
import { IonRouterOutlet } from '@ionic/angular/standalone';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { StatusBar, Style } from '@capacitor/status-bar';
import { getBase64Images } from './shared';
import { ApplicationInitializerService, AuthStateService } from './services';
import { Platform } from '@ionic/angular/standalone';
import { Keyboard } from '@capacitor/keyboard';

@Component({
  selector: 'app-root',
  template: ` <ion-app>
    <ion-router-outlet></ion-router-outlet>
  </ion-app>`,
  standalone: true,
  imports: [RouterOutlet, IonApp, CommonModule, IonRouterOutlet],
})
export class AppComponent implements AfterViewInit, OnInit {
  private router = inject(Router);
  private authStateService = inject(AuthStateService);
  private appInitializerService = inject(ApplicationInitializerService);
  private platform = inject(Platform);

  constructor(private zone: NgZone) {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const slug = event.url.split('.app').pop();
        if (slug) {
          this.router.navigateByUrl(slug);
        }
      });
    });
  }
  async ngOnInit(): Promise<void> {
    const ready = await this.platform.ready();
    if (ready) {
      document.querySelector('ion-backdrop')?.setAttribute('inert', 'true');
      if (Capacitor.isNativePlatform()) {
        App.addListener('appStateChange', async (state) => {
          if (state.isActive) {
            await this.authStateService.checkAuthState();
            this.appInitializerService.appStateChangedNotifier.next();
          }
        });
      }
    }

    await this.authStateService.checkAuthState();

    if (Capacitor.isNativePlatform() && this.platform.is('ios')) {
      await Keyboard.setAccessoryBarVisible({ isVisible: true });
      await Keyboard.setScroll({ isDisabled: false });
    }
  }

  async ngAfterViewInit() {
    this.globalClickListener();
    if (Capacitor.isNativePlatform()) {
      if (Capacitor.getPlatform() === 'android') {
        StatusBar.setOverlaysWebView({ overlay: false });
        await StatusBar.setStyle({ style: Style.Light });
        await StatusBar.show();
        await StatusBar.setBackgroundColor({ color: '#ffffff' });
      }
    }
    await getBase64Images();
  }

  globalClickListener = () => {
    if (Capacitor.isNativePlatform()) {
      document.addEventListener('click', async (event: any) => {
        if (event.target.matches('button') || event.target.matches('a')) {
          await HapticHelper.impact();
        }
      });
    }
  };
}
