import { Routes } from '@angular/router';
import { splashGuard } from './services/guards/splash.guard';

export const appRoutes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./features/auth/routes').then((m) => m.authRoutes),
  },
  {
    path: 'home',
    loadChildren: () => import('./features/home/routes').then((m) => m.homeRoutes),
  },
  {
    path: 'splash',
    loadChildren: () => import('./features/common/routes').then((m) => m.commonRoutes),
    canActivate: [splashGuard],
  },
  {
    path: '',
    redirectTo: '/splash',
    pathMatch: 'full',
  },
  { path: '**', redirectTo: '/splash' },
];
