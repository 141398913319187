import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class VersionCheckService {
  private readonly APP_VERSION = environment.version; // Ensure this matches your environment variable
  private readonly LOCAL_STORAGE_KEY = 'appVersion';
  private readonly DB_NAME = 'appDatabase';

  constructor() {}

  checkAndClearIndexedDB(): void {
    console.log('Starting check and clear IndexedDB process');

    const storedVersion = localStorage.getItem(this.LOCAL_STORAGE_KEY);

    console.log(`Stored version: ${storedVersion}, App version: ${this.APP_VERSION}`);

    if (storedVersion !== this.APP_VERSION) {
      console.log('Version mismatch, clearing all IndexedDB databases');
      this.clearAllIndexedDB()
        .then(() => {
          localStorage.setItem(this.LOCAL_STORAGE_KEY, this.APP_VERSION);
          console.log('App version updated in local storage');
        })
        .catch((error) => {
          console.error('Error during version check and IndexedDB clearance:', error);
        });
    } else {
      console.log('App version is up-to-date, no need to clear IndexedDB');
    }
  }

  private async clearAllIndexedDB(): Promise<void> {
    console.log('Clearing all IndexedDB databases');
    const databases = await indexedDB.databases();
    const promises = databases.map((dbInfo) => {
      if (dbInfo.name) {
        return this.deleteDatabase(dbInfo.name);
      }
      return Promise.resolve();
    });
    await Promise.all(promises);
    console.log('All databases cleared');
  }

  private deleteDatabase(dbName: string): Promise<void> {
    return new Promise((resolve, reject) => {
      console.log(`Attempting to delete database: ${dbName}`);
      const deleteRequest = indexedDB.deleteDatabase(dbName);

      deleteRequest.onsuccess = () => {
        console.log(`Database ${dbName} cleared`);
        resolve();
      };

      deleteRequest.onerror = () => {
        console.error(`Error clearing database ${dbName}`);
        reject();
      };

      deleteRequest.onblocked = () => {
        console.warn(`Delete request for ${dbName} is blocked`);
        resolve(); // Resolve to avoid getting stuck
      };
    });
  }
}
